import { type ReactElement } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';

import { trackEecPromotionClick, trackEecPromotionImpression } from '@jsmdg/tracking';
import { Banner } from '@jsmdg/yoshi';
import { CreativePlaceholderId } from '../../../../shared/enums/creativePlaceholderId';
import { type PageType as Type } from '../../../../shared/enums/pageType';
import { type CampaignCreative } from '../../../../shared/types/campaignCreative';
import { trackingTypeByPage } from '../../../enums/trackingPageTypes';
import { CreativeName, PromotionType } from '../../../enums/trackingPromotions';
import { useImpressionTracking } from '../../../hooks';

type BannerProps = {
    readonly creative: CampaignCreative;
    readonly index: number;
    readonly verticalPosition: number;
    readonly listCreativeIndex: number;
    readonly className: string;
    readonly pageType: Type;
    readonly isListView: boolean;
};

const messages = defineMessages({
    day: {
        defaultMessage: 'Tag',
    },
    days: {
        defaultMessage: 'Tagen',
    },
    hour: {
        defaultMessage: 'Stunde',
    },
    hours: {
        defaultMessage: 'Stunden',
    },
    minute: {
        defaultMessage: 'Minute',
    },
    minutes: {
        defaultMessage: 'Minuten',
    },
    seconds: {
        defaultMessage: 'Sekunden',
    },
});

const isFullWidthBanner = (placeholderId: CreativePlaceholderId): boolean => {
    return [
        CreativePlaceholderId.SelectionBlockDesktop,
        CreativePlaceholderId.ProductTileMobile,
    ].includes(placeholderId);
};

const isGifImageUrl = (url: string): boolean => {
    return url.toLowerCase().endsWith('.gif');
};

const ProductBanner = ({
    className,
    creative,
    index,
    isListView,
    listCreativeIndex,
    pageType,
    verticalPosition,
}: BannerProps): ReactElement | null => {
    const int = useIntl();
    const position = isListView
        ? `vertical${listCreativeIndex}_horizontal1`
        : `vertical${verticalPosition}_horizontal${index + 1}`;
    const name = creative.campaignName || 'no name';
    const id = creative.trackingName || 'no id';

    const promotionTrackingPayload = {
        id,
        name,
        creative: `${creative.placeholderId}_${trackingTypeByPage[pageType]}`,
        position,
    };

    const promotionTrackingEventData = {
        creativeName: CreativeName.ProductTile,
        creativeSlot: position,
        promotionName: name,
        promotionId: id,
        locationId: creative.url || '',
        promotionCountdown: creative.countdown ? 'true' : 'false',
        promotionPlaceholder: creative.placeholderId || '',
        promotionType: PromotionType.Dynamic,
    };

    const setImpressionTrackedElement = useImpressionTracking(() =>
        trackEecPromotionImpression([promotionTrackingPayload], promotionTrackingEventData),
    );

    const isMobileCreative = creative.placeholderId === CreativePlaceholderId.ProductTileMobile;
    const isGifImage = isGifImageUrl(creative.image.url);
    const imageUrl = isGifImage
        ? creative.image.url
        : creative.imgixImage?.src || creative.image.url;

    return (
        <div
            className={classNames(
                className,
                'justify-content-center',
                {
                    'd-none d-xs-flex d-md-none':
                        creative.placeholderId === CreativePlaceholderId.ProductTileTablet,
                    'd-flex d-xs-none': isMobileCreative,
                    'd-none d-md-flex':
                        creative.placeholderId === CreativePlaceholderId.SelectionBlockDesktop,
                },
                isListView && {
                    'd-none d-md-flex':
                        creative.placeholderId === CreativePlaceholderId.ProductListDesktop,
                    'd-none':
                        creative.placeholderId === CreativePlaceholderId.ProductTileDesktopLarge ||
                        creative.placeholderId === CreativePlaceholderId.ProductTileDesktopSmall,
                },
                !isListView && {
                    'd-none d-lg-flex':
                        creative.placeholderId === CreativePlaceholderId.ProductTileDesktopLarge,
                    'd-none d-md-flex d-lg-none':
                        creative.placeholderId === CreativePlaceholderId.ProductTileDesktopSmall,
                },
            )}
            key={`banner-${creative.placeholderId}-${index}`}
            data-testid="campaignBanner"
        >
            <Banner
                imageUrl={imageUrl}
                alt={creative.image.alt}
                link={creative.url}
                text="Endet in"
                isWide={creative.placeholderId === CreativePlaceholderId.ProductListDesktop}
                msCountdown={
                    creative.countdown
                        ? new Date(creative.countdown.endDateUtc).getTime() - Date.now()
                        : undefined
                }
                countdownUnits={{
                    days: {
                        single: int.formatMessage(messages.day),
                        plural: int.formatMessage(messages.days),
                    },
                    hours: {
                        single: int.formatMessage(messages.hour),
                        plural: int.formatMessage(messages.hours),
                    },
                    minutes: {
                        single: int.formatMessage(messages.minute),
                        plural: int.formatMessage(messages.minutes),
                    },
                    seconds: {
                        plural: int.formatMessage(messages.seconds),
                    },
                }}
                textColor={creative.countdown?.color || ''}
                lazyLoad
                ref={setImpressionTrackedElement}
                onClick={() => {
                    trackEecPromotionClick(promotionTrackingPayload, promotionTrackingEventData);
                }}
                fullWidth={isFullWidthBanner(creative.placeholderId)}
                skeletonClasses=""
                srcSet={isGifImage ? '' : creative.imgixImage?.srcset}
                sizes={isGifImage ? '' : creative.imgixImage?.sizes}
                width={isGifImage ? undefined : creative.imgixImage?.width}
                height={isGifImage ? undefined : creative.imgixImage?.height}
                hasAspectRatio={isMobileCreative}
            />
        </div>
    );
};

export { ProductBanner };
